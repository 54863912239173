import React, { useState } from "react"
import { getUser, isLoggedIn } from "../../services/auth"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../Page"
import SwiperBoardItems from "../../components/ContentBuilder/SwiperList/SwiperBoardItems"
import SwiperSelectionBoardItems from "../../components/ContentBuilder/SwiperList/SwiperSelectionBoardItems"
import RecentViews from "../../components/RecentViews"
import CreateOnlyBoardModal from "../../components/ContentBuilder/Modal/CreateOnlyBoardModal"

const MyBoardsPagePage = () => {
     const GlobalConfigData = useStaticQuery(graphql`
    {
    silverStripeDataObject(className: {eq: "Undigital__Objects__GlobalConfig"}) {
        UndigitalGlobalConfig {
            hideSelectionBoard
        }
      }
    }
    `)
    const globalConfig = GlobalConfigData.silverStripeDataObject.UndigitalGlobalConfig
   setTimeout(function(){
        if (!isLoggedIn()) {
            if(typeof window !== 'undefined'){            
                window.location.href = "/login/";
            }
        }
    }, 500); 
  var Member = getUser()
  var nameText = '';
  var firstName = Member.FirstName
  if(firstName){
      nameText += firstName.charAt(0);
  }
  var lastName = Member.Surname
  if(lastName){
      nameText += lastName.charAt(0);
  }
  var boards = []
  if (typeof Member.Boards !== "undefined" && Member.Boards !== null) {
    boards = Object.keys(Member.Boards).map(key => Member.Boards[key])
    boards.sort((a, b) => {
        return a.ID < b.ID ? 1 : -1
    })
  }
  
  const [showAddBoard, setShowAddBoard] = useState(false)
  return(
    <Layout>
      <div className="container">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/explore/">Explore</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              My Boards
            </li>
          </ol>
        </nav>

        <div className="account-avt-wrap">
          <div className="account-avt">
            <a href="/account/my-boards/" className="account-avt-inner">
              <span>{nameText}</span>
            </a>
          </div>
          <p className="account-avt-name d-none d-lg-block">
            {Member.FirstName}
          </p>
        </div>

        <div className="row no-gutters justify-content-center">
          <div className="col-12 col-lg-6 col-xl-5">
            <div className="my-account-header-wrap">
              <a className="my-account-header" href="/account/">
                <div className="my-account-header-title">My account</div>
              </a>
              <a className="my-account-header active" href="/account/my-boards/">
                <div className="my-account-header-title">My boards</div>
              </a>
            </div>
          </div>
        </div>

        <div className="board-page-title">
          <div className="row align-items-center">
            <div className="col-md">
              <h1 className="product-name-title text-uppercase">
                Mood Boards   
                <a href="#" onClick={() => setShowAddBoard(true)}>
                <svg className="ml-3 ml-lg-4 cursor-pointer" width="43px" height="43px">
                  <use xlinkHref="/img/icons.svg#icon-circle-plus"></use>
                </svg>
                </a>
              </h1>
            </div>
          </div>
        </div>
        <SwiperBoardItems title="" navClass="board" boards={boards} />
        {globalConfig.hideSelectionBoard == 0 && (
          <>
          <div className="board-page-title">
            <div className="row align-items-center">
              <div className="col-md">
                <h1 className="product-name-title text-uppercase">
                  Selection Boards   
                  <a href="#" onClick={() => setShowAddBoard(true)}>
                  <svg className="ml-3 ml-lg-4 cursor-pointer" width="43px" height="43px">
                    <use xlinkHref="/img/icons.svg#icon-circle-plus"></use>
                  </svg>
                  </a>
                </h1>
              </div>
            </div>
          </div>
          <SwiperSelectionBoardItems title="" navClass="board" boards={boards} />
        </>
        )}
        <RecentViews/>
      </div>
      <CreateOnlyBoardModal
        show={showAddBoard}
        handleClose={() => setShowAddBoard(false)}
      />
    </Layout>
  )
}
export default MyBoardsPagePage