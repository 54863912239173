import React, { useEffect, useState } from "react"
import { Modal } from "react-bootstrap"
import ReactCSSTransitionGroup from 'react-transition-group'
import { getUser, setUser, isLoggedIn } from "../../../services/auth"
import config from '../../../config/config'
import axios from "axios";
import styles from "./CreateBoardModal.scss"
import { getBoardFromLocalStorage, updateBoard } from "../../../state/board"
import { connect } from "react-redux"

const CreateOnlyBoardModal = ({ show, handleClose, dispatch }) => { 
  var Member = getUser();
  useEffect(() => {
    dispatch(getBoardFromLocalStorage())   
  }, [])
  const hideBoard = (event) => {
      handleClose()
  }
  const createBoard = (event) => {
    event.preventDefault();
    let boardname = event.target.elements.boardname.value;
    if (isLoggedIn()) {
      var _data = {
        enu: btoa(Member.Email),
        boardname: boardname,
      };

      axios.post(config.host + "/tribecaapi/createboard", _data)
        .then(response => {
          let _response_data = response.data;
          if (_response_data) {
            Member.Boards = _response_data;
            setUser(Member);
            dispatch(updateBoard(Object.keys(Member.Boards).map(key => Member.Boards[key])))
          }
          handleClose()
        }).catch(function (error) {
        })
    }
  }
  return (
    <Modal
      className="boardModal"
      show={show}
      onHide={handleClose}
      animation={true}
    >
      <div className="boardModal-inner">
          <>
            <h4>CREATE A BOARD</h4>
            <form onSubmit={event => { createBoard(event) }}>
              <div className="form-group">
                <input name="boardname" placeholder="New board name" className="form-control" />
              </div>
              <div className="d-flex justify-content-between mt-5">
                <button className="btn text-cancel px-0" onClick={() => handleClose()}>
                  Cancel
                </button>
                <button className="btn btn-black btn-lg btn-w160">CREATE</button>
              </div>
            </form>
          </>
      </div>
    </Modal>
  )
}

const mapStateToProps = (state) => ({
  currentBoards: state.board.all,
})

export default connect(mapStateToProps, null)(CreateOnlyBoardModal)
