import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"

const SwiperSelectionBoardItems = ({ title, navClass, boards }) => {
    const GlobalConfigData = useStaticQuery(graphql`
    {
    silverStripeDataObject(className: {eq: "Undigital__Objects__GlobalConfig"}) {
        UndigitalGlobalConfig {
            hidePricingGlobally
            hideStyleSection
            hideHomeDesignSection
            hideFacadesSection    
            hideFinishesFixturesSection
            hideCabinetrySection
            hideFurnitureSection
        }
      }
    }
    `)
    const globalConfig = GlobalConfigData.silverStripeDataObject.UndigitalGlobalConfig
  var totalBoards = boards.length
  if (totalBoards > 0) {
    var i = 0
    for (; boards[i]; ) {
      var board = boards[i]
      board.totalPins = 0
      if (
        typeof board !== "undefined" &&
        board !== "" &&
        board.Items !== null
      ) {
        if (typeof board.Items !== "undefined") {
          var itemsJSON = JSON.parse(board.Items)
          if (itemsJSON.length !== 0) {
            var items = Object.keys(itemsJSON).map(key => itemsJSON[key])
            items = items.filter(function (it){
                if (it.Link.includes("finishes-fixtures") && globalConfig.hideFinishesFixturesSection === 1){
                      return false
                }
                if (it.Link.includes("cabinetry") && globalConfig.hideCabinetrySection === 1){
                      return false
                }
                if (it.Link.includes("home-designs") && globalConfig.hideHomeDesignSection === 1){
                      return false
                }
                if (it.Link.includes("facade") && globalConfig.hideFacadesSection === 1){
                      return false
                }
                if (it.Link.includes("furniture") && globalConfig.hideFurnitureSection === 1){
                      return false
                }
                if (it.Link.includes("style") && globalConfig.hideStyleSection === 1){
                      return false
                }
                return true
            })
            board.totalPins = items.length
            if (typeof items[0] !== "undefined") {
              board.Item1 = items[0]
            }
            if (typeof items[1] !== "undefined") {
              board.Item2 = items[1]
            }
            if (typeof items[2] !== "undefined") {
              board.Item3 = items[2]
            }
          }
        }
      }
      i++
    }
  }

  return (
    <div className="row explore-wrap mt-0">
      {boards.map((board, idx) => (
        <div key={`swiper-board-${board.ID}`} className="col-6 col-lg-4">
          <div className={`grid-item showtitle${!board.Item1 ? " no-image" : ""}`}>
            <button
              type="button"
              className="btn-like"
            >
              <svg id="icon-heart" width="35" height="35" viewBox="0 0 35 35"><path d="M-4.556-1.307c-5.906-10.042-18.85-3.3-16.35,8.076C-18.458,17.9-4.556,23.792-4.556,23.792S9.348,17.9,11.794,6.769C14.295-4.611,1.352-11.349-4.556-1.307Z" transform="translate(21.982 6.975)" fill="currentColor" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1.5"></path></svg>
            </button>

            <a href={`/account/board?id=${board.ID}`} className={`grid-item-inner`}>
              {board.Item1 && (
                <img src={board.Item1.Image} alt={board.Item1.Title}></img>
              )}
              <div className="grid-caption">
                <h4>{board.Name}</h4>
                <div className="grid-bottom">
                  <p className="variant-name">{board.totalPins} ITEMS</p>
                </div>
              </div>
            </a>
          </div>
        </div>
      ))}
      <div className="col-6 col-lg-4">
          <div className="addmore-wrap">
            <a href="/explore" className="addmore-tile">
              <svg className="addmore-icon" width="30px" height="30px">
                <use xlinkHref="/img/icons.svg#icon-plus"></use>
              </svg>
              <div className="addmore-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="35"
                  height="35"
                  viewBox="0 0 35 35"
                >
                  <path
                    d="M-4.556-1.307c-5.906-10.042-18.85-3.3-16.35,8.076C-18.458,17.9-4.556,23.792-4.556,23.792S9.348,17.9,11.794,6.769C14.295-4.611,1.352-11.349-4.556-1.307Z"
                    transform="translate(21.982 6.975)"
                    fill="#000"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    strokeWidth="1.5"
                  />
                </svg>
                <h4>CREATE A<br/>SELECTIONS<br/>BOARD</h4>
              </div>
              <div className="addmore-bottom"><p>DETAIL YOUR HOME SELECTONS</p></div>
            </a>
        </div>
        </div>
    </div>
  )
}

SwiperSelectionBoardItems.propTypes = {
  navClass: PropTypes.string.isRequired,
}

export default SwiperSelectionBoardItems
